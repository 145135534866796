import { parseToRgb } from 'polished';

const brandColors = {
    primary: '#00B1D1',
    primarySelect: '#009BB8',
    disabled: '#B5DAEB',
    secondary: '#0179A8',
    secondarySelect: '#01678F',
    tertiary: '#E9FEFF',
    welcomeCircles: '#0B719D',
};

const systemColors = {
    textPrimary: '#404040',
    textSecondary: '#6F6F6F',
    textDisabled: '#949494',
    tertiaryButton: '#9B9B9B',
    tertiaryButtonSelect: '#828282',
    mediumGrey: '#CCCCCC',
    lightGrey: '#EDEDED',
    pageBackground: '#F5F5F5',
    cardBackground: '#FFFFFF',
    error: '#FF4C5D',
    notification: '#FF4C5D',
    validation: '#6C9A37',
    primaryRgb: Object.values(parseToRgb(brandColors.primary)).join(', '),

    // colors below are not from specification so they might will change
    // AVOID TO USE IT
    white: '#FFFFFF',
};

const wheelColors = {
    lockedWheel: '#EBEBEB',
    nutritionWheel: '#F9D77B',
    indulgencesWheel: '#C0E78E',
    movementWheel: '#9CEAEB',
    mindfulnessWheel: '#B5A3E8',
    sleepWheel: '#A0D1F7',
    bodyWheel: '#A4EDD2',
};

const mapping = {
    nav: 'cardBackground',
};

export default {
    colors: {
        ...brandColors,
        ...systemColors,
        ...wheelColors
    },
    mapping,
};
